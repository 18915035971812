<template>
    <div
        v-outside-click="handleOutsideClick"
        class="vertical-menu">
        <button
            @click="open = !open">
            <div class="dot" />
            <div class="dot" />
            <div class="dot" />
        </button>
        <div
            class="floating-menu"
            :class="{
                'visible': open
            }">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            open: false,
        };
    },
    methods: {
        handleOutsideClick() {
            this.open = false;
        }
    }
};
</script>

<style lang="scss" scoped>
.vertical-menu {
    position: relative;
    & > button {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        &:hover {
            .dot {
                background: #94999C;
            }
        }
        .dot {
            width: 6px;
            height: 6px;
            margin: 3px;
            border-radius: 50%;
            background: #B3C2C9;
            transition: background-color 0.2s ease-in-out;
        }
    }
}
.floating-menu {
    position:absolute;
    background: white;
    border-radius: 5px;
    z-index: 88;
    min-width: 200px;
    right: calc(100% + 8px);
    top: 0;
    padding: 25px;
    text-align: left;
    filter: drop-shadow(0px 0px 10px rgba(0,0,0,0.2));
    display: none;
    & > button {
        margin-bottom: 15px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &:after{
        content: '';
        position:absolute;
        width: 15px;
        height: 15px;
        transform: rotate(45deg);
        right: -5px;
        top: 11px;
        background: white;
        z-index: 2;
    }
    &:before{
        content: '';
        position:absolute;
        width: 25px;
        height: 40px;
        right:0;
        top:25px;
        background: white;
        z-index: 3;
    }
    &.visible{
        display: flex;
        flex-direction: column;
    }
}
</style>
