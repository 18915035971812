<template>
    <styled-tooltip
        position="right"
        :max-width="500"
        :disabled="!ads.length">
        <template #content>
            <div class="ads-container">
                <div
                    v-for="ad in ads"
                    :key="ad.id"
                    class="ad">
                    <ad-asset
                        :ad="ad"
                        size="40px" />
                    <span>{{ ad.display_name }}</span>
                </div>
            </div>
        </template>
        <slot />
    </styled-tooltip>
</template>

<script>
import AdAsset from '@/components/globals/AdAsset';
import StyledTooltip from '@/components/globals/StyledTooltip';

export default {
    components: {
        StyledTooltip,
        AdAsset
    },
    props: {
        ads: {
            type: Array,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    }
};
</script>


<style lang="scss" scoped>
.ads-container {
    min-width: 300px;
}
.ad {
    display: flex;
    align-items: center;
    border-radius: 7px;
    padding: 15px;
    background-color: #F7F8FC;
    margin-bottom: 10px;
    &:last-child {
        margin-bottom: 0;
    }
    .ad-asset {
        margin-right: 15px;
    }
    span {
        color: $blue-bg;
        font-size: 14px;
        line-height: 15px;
    }
}
</style>