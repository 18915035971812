<template>
    <div>
        <styled-dialog
            v-model="detailsOpen"
            class="text-xs-center">
            <template #heading>
                <h2>Ad Building Error</h2>
            </template>
            <p>The following error occurred while build ads:</p>
            <copy-text-area
                hide-email
                hide-download>
                {{ errorMessage }}
            </copy-text-area>
            <div class="text-xs-center mt-5">
                <styled-button
                    @click="detailsOpen = false">
                    Close
                </styled-button>
            </div>
        </styled-dialog>
        <div class="progress">
            <div class="progress__icon">
                <icon
                    :name="channelDetails.icon_name"
                    color="#909FA8"
                    size="25" />
            </div>
            <div class="progress__info">
                <div
                    v-if="currentStatus === STATUS_COMPLETE"
                    class="progress__message">
                    <div class="progress__completed">
                        <div class="progress__completed-message">
                            <icon
                                name="indicator-success"
                                class="mr-1"
                                color="#A2CF75"
                                size="20" />
                            {{ completedStatusMessage }}
                        </div>
                    </div>
                </div>
                <div
                    v-else-if="currentStatus === STATUS_FAILED"
                    class="progress__error">
                    <icon
                        name="indicator-warning"
                        color="#EF5542"
                        size="20" />
                    <span v-if="hasCustomMessage">
                        {{ customMessage }}
                    </span>
                    <span v-else>
                        Deployment failed.  Please
                        <styled-button
                            text
                            @click="openSupportTicketSlideOut">
                            contact support
                        </styled-button> for assistance with this issue.
                        If you would like to investigate the error
                        yourself
                        <styled-button
                            text
                            @click="detailsOpen = true">
                            click here for details
                        </styled-button>.
                    </span>
                </div>
                <template
                    v-else-if="(
                        currentStatus === STATUS_BUILDING
                        || currentStatus === STATUS_QUEUED
                    )">
                    <div
                        v-if="isAutomatedPlay"
                        class="progress__loader">
                        <div class="progress__bar progress__bar-1" />
                        <div class="progress__bar progress__bar-2" />
                    </div>
                    <div class="progress__message">
                        <icon
                            name="indicator-warning"
                            class="mr-1"
                            color="#F8C41C"
                            size="20" />
                        {{ currentStatusMessage }}
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import CopyTextArea from '@/components/globals/CopyTextArea';
import StyledDialog from '@/components/globals/StyledDialog';
import Icon from '@/components/globals/Icon';
import StyledButton from '@/components/globals/StyledButton';
import { getChannelByPlatformName } from '@/components/onboarding/channel-setup/channels';
import {
    STATUS_BUILDING,
    STATUS_FAILED,
    STATUS_COMPLETE,
    STATUS_QUEUED
} from '../../constants';
import { mapActions, mapState } from 'vuex';
import stripIndent from 'strip-indent';
import { DYNAMIC_DEPLOYMENT_TYPE } from '@/components/ad-deployment/store/constants';

export default {
    components: {
        Icon,
        StyledButton,
        StyledDialog,
        CopyTextArea,
    },
    props: {
        deployment: {
            type: Object,
            required: true
        },
        play: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            messages: [],
            detailsOpen: false,
            echoChannel: null,
            STATUS_BUILDING,
            STATUS_FAILED,
            STATUS_COMPLETE,
            STATUS_QUEUED
        };
    },
    computed: {
        ...mapState({
            dealer: (state) => state.dealer.currentDealer
        }),
        channelDetails() {
            return getChannelByPlatformName(this.play.platform.name);
        },
        currentStatus() {
            return this.latestMessage
                ? this.latestMessage.status
                : this.play.status;
        },
        errorMessage() {
            if (this.currentStatus !== STATUS_FAILED) {
                return null;
            }

            // If pusher set the failure show it
            if (this.latestMessage) {
                return this.latestMessage.message;
            }

            // Fall back to the play error by default
            return this.play.configuration.exception_message;
        },
        currentStatusMessage() {
            if (this.latestMessage) {
                return this.latestMessage.message;
            }

            if (this.hasCustomMessage) {
                return this.play?.configuration?.message;
            }

            if (this.currentStatus === STATUS_QUEUED) {
                return 'Deployment queued...';
            }

            return 'Deployment in progress...';

        },
        latestMessage() {
            return this.messages?.[this.messages?.length - 1];
        },
        customMessage() {
            return this.play?.configuration?.message;
        },
        isDynamicDeployment() {
            return this.deployment.deployment_type === DYNAMIC_DEPLOYMENT_TYPE;
        },
        isAutomatedPlay() {
            return this.play.fulfillment_type === 'automated';
        },
        hasCustomMessage() {
            return this.isDynamicDeployment && this.customMessage;
        },
        completedStatusMessage() {
            if (this.hasCustomMessage) {
                return this.customMessage;
            }

            return `Deployment to ${this.channelDetails.display_name} successfully completed!`;
        }
    },
    watch: {
        latestMessage(value) {
            if (value.status === STATUS_COMPLETE) {
                this.$emit('complete', this.play);
            } else if (value.status === STATUS_FAILED) {
                this.$emit('failed', this.play);
            }
        }
    },
    created() {
        this.createListener();
    },
    destroyed() {
        this.removeListener();
    },
    methods: {
        ...mapActions([
            'createTicket'
        ]),
        handleViewAds() {

        },
        handleViewPreviews() {

        },
        messageListener(e) {
            this.messages.push(e);
        },
        createListener() {
            if ([STATUS_BUILDING, STATUS_QUEUED].includes(this.play.status)) {
                this.echoChannel = this.$echo
                    .channel(`play-build-channel-${this.play.uuid}`)
                    .listen(
                        'PlayBuilderEvent',
                        this.messageListener.bind(this)
                    );
            }
        },
        removeListener() {
            if (this.echoChannel) {

                const formatter = this.echoChannel
                    .eventFormatter
                    .format('PlayBuilderEvent');

                this.echoChannel.subscription
                    .unbind(formatter, this.messageListener);
            }
        },
        openSupportTicketSlideOut() {
            this.createTicket({
                subject: `Ad Deployment Error - ${this.dealer.name}`,
                type: 'Other',
                content: stripIndent(`
                    An ad deployment error has occurred:
                    ${this.errorMessage}

                    Please assist as soon as possible!

                    #For Internal Use#
                    Account name: ${this.dealer.name}
                    ID: ${this.dealer.id}
                    Organization: ${this.dealer.agency.name}
                    Ad Deployment ID: ${this.deployment.id}
                    Execution ID: ${this.play.configuration.execution_id}
                    Dealer Play ID: ${this.play.id}
                `)
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.progress{
    margin-left: 15px;
    position: relative;
    padding-bottom: 20px;
    min-width: 70%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    &:last-of-type{
        padding-bottom: 0;
        &:after{
            display: none;
        }
    }
    &:after{
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: 0;
        left:0;
        right: 0;
        background: #DFE4E6;
    }
    &__info{
        margin-left: 20px;
        width: calc(100% - 30px);
    }
    &__icon{
        width: 30px;
    }
    &__error{
        color: #EF5542;
        display: flex;
        align-items: center;
        span{
            margin-left: 10px;
        }
        button.text {
            color: #EF5542;
        }
    }
    &__completed {
        display: flex;
    }
    &__completed-message {
        margin-right: 20px;
    }
    &__completed-actions {
        margin-left: auto;
        button.text {
            margin-left: 15px;
        }
    }
}

.progress__loader {
    width: 100%;
    height: 10px;
    margin-bottom: 5px;
    position: relative;
    background: #ddd;
    overflow: hidden;
}

.progress__bar {
    display: block;
    position: absolute;
    width: 40%;
    height: 100%;
    background: #00A4EB;
    margin-left: -100%;
}

.progress__bar-1 {
    animation: first-motion 2s cubic-bezier(.3,.89,.71,.37) infinite;
}

.progress__bar-2 {
    animation: second-motion 2s cubic-bezier(.5,.35,.8,.62) infinite;
}

@keyframes first-motion {
    /* start */
    0% {
        margin-left: -100%;
        width: 40%;
    }

    /* finish */
    60% {
        margin-left: 100%;
        width: 80%;
    }

    /* stay put */
    100% {
        margin-left: 100%;
    }
}

@keyframes second-motion {
    /* stay put */
    0% {
        margin-left: -100%;
        width: 40%;
    }

    /* start */
    40% {
        margin-left: -100%;
        width: 40%;
    }

    /* fully in frame */
    60% {
        width: 40%;
    }

    /* middle */
    80% {
        width: 80%;
    }

    /* finish */
    100% {
        margin-left: 100%;
        width: 100%;
    }
}
</style>
