<template>
    <styled-button
        class="object-button"
        :text="!button"
        :small="button"
        :loading="loading"
        @click="handleViewObjects">
        <span class="object-name">View/Manage {{ objectName }}</span>
    </styled-button>
</template>

<script>
import StyledButton from '@/components/globals/StyledButton';
import { mapMutations, mapState } from 'vuex';
import { OBJECT_TYPE_AD, OBJECT_TYPE_ADSET, OBJECT_TYPE_CAMPAIGN } from '@/helpers/globals';

export default {
    components: {
        StyledButton
    },
    props: {
        deployment: {
            type: Object,
            required: true
        },
        play: {
            type: Object,
            default: undefined
        },
        button: {
            type: Boolean,
            default: false
        },
        objectType: {
            type: String,
            default: 'ads'
        },
    },
    data() {
        return {
            loading: false
        };
    },
    computed: {
        ...mapState({
            dealerId: (state) => state.dealer.currentDealerId,
            hasBeenRedirected: (state) => state.playbookManagement.hasBeenRedirected
        }),
        /**
         * Navigates the plays to act on based on whether
         * this is being called for a single play or the
         * whole deployment
         */
        targetPlays() {
            if (this.play) {
                return [this.play];
            }

            return this.deployment.dealer_plays?.data ?? [];
        },
        /**
         * Builds up the ad data to be passed into
         * `playbookManagement/viewObjects` in the
         * `{ [platform]: [...objectIds] }` format
         */
        adData() {
            return this.targetPlays.reduce((data, play) => {
                const adIds = play.object_ids.map(obj => obj.ad_id);
                data[play.platform.name] = adIds;
                return data;
            }, {});
        },
        adSetData() {
            return this.targetPlays.reduce((data, play) => {
                const adIds = play.object_ids.map(obj => obj.ad_set_id);
                data[play.platform.name] = adIds;
                return data;
            }, {});
        },
        campaignData() {
            return this.targetPlays.reduce((data, play) => {
                const adIds = play.object_ids.map(obj => obj.campaign_id);
                data[play.platform.name] = adIds;
                return data;
            }, {});
        },
        objectName() {
            if (this.objectType === 'adsets') {
                return 'Sets';
            }
            if (this.objectType === 'campaigns') {
                return 'Campaigns';
            }
            return 'Ads';
        }
    },
    created() {
        const { ad_deployment_id, selected_tab } = this.$route.query;

        if (!this.hasBeenRedirected && Number.parseInt(ad_deployment_id) === this.deployment.id && this.objectType === selected_tab) {
            this.setPlaybookManagementValue({ key: 'hasBeenRedirected', value: true });
            this.handleViewObjects();
        }
    },
    methods: {
        ...mapMutations({
            setPlaybookManagementValue: 'playbookManagement/setPlaybookManagementValue'
        }),
        handleViewObjects() {
            const { ad_deployment_id, selected_tab } = this.$route.query;

            let objectType = OBJECT_TYPE_AD;

            if (this.objectType === 'adsets') {
                objectType = OBJECT_TYPE_ADSET;
            }

            if (this.objectType === 'campaigns') {
                objectType = OBJECT_TYPE_CAMPAIGN;
            }

            if (!selected_tab || !ad_deployment_id) {
                this.$router.push({ query: {
                    ad_deployment_id: this.deployment.id,
                    selected_tab: this.objectType
                } });
            }

            this.$store.dispatch('playbookManagement/viewObjects', {
                type: objectType,
                data: {
                    ad_data: this.adData,
                    ad_set_data: this.adSetData,
                    campaign_data: this.campaignData
                }
            });
        },
    }
};
</script>

<style lang="scss" scoped>
button.object-button {
    display: block;
    width: 100%;
    position: relative;
    text-decoration: none;
    padding-bottom: 10px;
    padding-right: 15px;
    margin-bottom: 20px;
    text-align: left;
    &:after {
        content: '';
        position: absolute;
        z-index: 3;
        top: calc(100% + 5px);
        width: calc(100% + 20px);
        height: 1px;
        left: -10px;
        background: $border-list;
    }
    &:hover {
        text-decoration: underline;
    }
}
.object-name {
    white-space: nowrap;
}
</style>
