<template>
    <div class="thumbnail">
        <ad-asset
            v-if="ads[1]"
            class="thumbnail-left"
            size="100%"
            :ad="ads[1]" />
        <ad-asset
            v-if="ads[0]"
            class="thumbnail-center"
            size="100%"
            :ad="ads[0]" />
        <ad-asset
            v-if="ads[2]"
            class="thumbnail-right"
            size="100%"
            :ad="ads[2]" />
    </div>
</template>

<script>
import AdAsset from '@/components/globals/AdAsset';

export default {
    components: {
        AdAsset
    },
    props: {
        ads: {
            type: Array,
            required: true
        }
    }
};
</script>

<style lang="scss" scoped>
.thumbnail {
    display: inline-block;
    position: relative;
    vertical-align: middle;
}

.thumbnail-center {
    position: relative;
    height: 50px;
    width: 50px;
    z-index: 10;
}

.thumbnail-left,
.thumbnail-right {
    position: absolute;
    height: 36px;
    width: 36px;
    z-index: 9;
    top: 50%;
    transform: translateY(-50%);
}

.thumbnail-left {
    left: -10px;
}

.thumbnail-right {
    right: -10px;
}

.thumbnail-left,
.thumbnail-right {
    filter: brightness(80%);
}
</style>
