<template>
    <ads-tooltip
        v-if="ads.length"
        :ads="ads">
        {{ label }}
    </ads-tooltip>
    <span v-else>{{ label }}</span>
</template>

<script>
import AdsTooltip from '@/components/globals/AdsTooltip';
import { STATIC_DEPLOYMENT_TYPE } from '@/components/ad-deployment/store/constants';

export default {
    components: {
        AdsTooltip
    },
    props: {
        deployment: {
            type: Object,
            required: true
        }
    },
    computed: {
        ads() {
            const { ads = [] } = this.deployment ?? {};
            return ads;
        },
        label() {
            if (this.deployment.deployment_type === STATIC_DEPLOYMENT_TYPE) {
                return this.ads.length > 1 ? 'Multiple Ads' : 'Single Ad';
            }

            return 'Inventory Ad';
        }
    }
};
</script>
