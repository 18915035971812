<template>
    <deployment-wrapper
        class="individual"
        :deployment="deployment">
        <vertical-menu
            v-if="hasFailure"
            class="individual__menu">
            <delete-button
                :deployment="deployment"
                confirm>
                <template #confirmation>
                    <h3>Are you sure you want to delete this deployment?</h3>
                    <p v-if="hasComplete">
                        Some channels completed deployment and will
                        <strong>not</strong> be removed when deleted but you can
                        still re-deploy the failed channels if you know what
                        happened. Or we'd encourage you to work with our support
                        team to resolve this issue.
                    </p>
                    <p v-else>
                        If you know what happened with the deployment
                        you can try and re-deploy the failed channels. Otherwise,
                        we'd encourage you to work with our support team to
                        resolve this issue.
                    </p>
                </template>
            </delete-button>
        </vertical-menu>
        <div class="individual__left">
            <div class="individual__left-title">
                {{ title }}
            </div>
            <div class="individual__info">
                <div class="individual__img">
                    <thumbnail
                        v-if="isStaticDeployment"
                        :ads="ads" />
                    <icon
                        v-else
                        class="mr-4"
                        name="car" />
                </div>
                <div class="individual__name">
                    <row-label :deployment="deployment" />
                </div>
            </div>
        </div>
        <div class="individual__right">
            <div class="individual__title">
                STATUS
            </div>
            <div
                v-if="allPlaysComplete && !currentDeploymentLoading"
                class="individual__complete">
                <div class="individual__complete-label">
                    <div>
                        <icon
                            v-for="channel in channelDetails"
                            :key="channel.icon_name"
                            :name="channel.icon_name" />
                    </div>
                    <div>
                        Ads successfully deployed to all platforms!
                    </div>
                </div>
                <div class="individual__complete-actions">
                    <view-ads-button
                        :deployment="currentDeployment"
                        button />
                    <view-previews-button
                        :deployment="currentDeployment"
                        button />
                </div>
            </div>
            <div
                v-else
                class="individual__progress">
                <play
                    v-for="play in plays"
                    :key="play.id"
                    :deployment="deployment"
                    :play="play"
                    @complete="handlePlayComplete"
                    @failed="handlePlayFailed" />
            </div>
        </div>
        <div
            v-if="!allPlaysComplete"
            class="individual__right fulfillment-type">
            <div class="individual__title">
                FULFILLMENT TYPE
            </div>
            <div
                v-for="play in plays"
                :key="play.id"
                class="individual__progress">
                <div class="has-tooltip">
                    {{ capitalizeWord(play.fulfillment_type) }}
                    <ad-deployment-tooltip
                        :content-path="`campaignManagement.fulfillmentType.tooltips.${play.fulfillment_type}`" />
                </div>
            </div>
        </div>
    </deployment-wrapper>
</template>

<script>
import VerticalMenu from '@/components/globals/VerticalMenu';
import Icon from '@/components/globals/Icon';
import Play from './Play';
import Thumbnail from '../Thumbnail';
import RowLabel from '../RowLabel';
import ViewAdsButton from '../ViewAdsButton';
import ViewPreviewsButton from '../ViewPreviewsButton';
import DeleteButton from '../DeleteButton';
import DeploymentWrapper from '../DeploymentWrapper';
import {
    STATUS_BUILDING,
    STATUS_QUEUED,
    STATUS_COMPLETE,
    STATUS_FAILED
} from '../../constants';
import { getChannelByPlatformKey } from '@/components/onboarding/channel-setup/channels';
import { sleep } from '@/helpers';
import { STATIC_DEPLOYMENT_TYPE } from '@/components/ad-deployment/store/constants';
import { capitalizeWord } from '@/helpers/capitalizeWord';
import AdDeploymentTooltip from '@/components/ad-deployment/components/AdDeploymentTooltip.vue';

export default {
    components: {
        AdDeploymentTooltip,
        Icon,
        Play,
        RowLabel,
        Thumbnail,
        ViewAdsButton,
        ViewPreviewsButton,
        VerticalMenu,
        DeleteButton,
        DeploymentWrapper,
    },
    props: {
        deployment: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            capitalizeWord,
            STATUS_COMPLETE,
            STATUS_BUILDING,
            STATUS_QUEUED,
            currentDeployment: null,
            currentDeploymentLoading: false,
            completedPlays: [],
            failedPlays: []
        };
    },
    computed: {
        channelDetails() {
            return this.plays.map(play => (
                getChannelByPlatformKey(play.platform.name)
            ));
        },
        title() {
            if (this.deployment?.deployment_type === STATIC_DEPLOYMENT_TYPE) {
                return 'INDIVIDUAL AD(S)';
            }

            return 'INVENTORY AD(S)';
        },
        ads() {
            return this.deployment?.ads ?? [];
        },
        plays() {
            return this.deployment?.dealer_plays?.data ?? [];
        },
        hasComplete() {
            // Check the server status if reloaded and not
            // getting pusher updates
            const hasExistingComplete = this.plays.some(play => (
                play.status === STATUS_COMPLETE
            ));

            if (hasExistingComplete) {
                return true;
            }

            return  Boolean(this.completedPlays.length);
        },
        hasFailure() {
            // If reloaded and not getting pusher updates
            if (this.deployment.status === STATUS_FAILED) {
                return true;
            }

            return Boolean(this.failedPlays.length);
        },
        allPlaysComplete() {
            // This probably can never happen but being safe!
            if (this.deployment.status === STATUS_COMPLETE) {
                return true;
            }

            return this.plays.length === this.completedPlays.length;
        },
        isStaticDeployment() {
            if (!this.deployment.deployment_type?.length) {
                return true;
            }

            return this.deployment.deployment_type === STATIC_DEPLOYMENT_TYPE;
        }
    },
    watch: {
        async allPlaysComplete(value) {
            if (value) {
                await this.updateCurrentDeployment();
            }
        }
    },
    created() {
        this.currentDeployment = this.deployment;
    },
    methods: {
        handlePlayComplete(play) {
            this.completedPlays.push(play);
        },
        handlePlayFailed(play) {
            this.failedPlays.push(play);
        },
        async updateCurrentDeployment() {
            try {
                this.currentDeploymentLoading = true;

                // Give the backend some time to update
                await sleep(1000);

                const response = await this.$apiRepository.getAdDeployment(this.deployment.id);
                this.currentDeployment = response.data.data;
                this.currentDeploymentLoading = false;
            } catch(error) {
                console.error('Error updating deployment', error.response ?? error);
                this.$flash('There was an error retrieving the finished deployment. Please try reloading');
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.individual{
    padding:10px 50px 10px 30px;
    display: flex;
    margin-bottom:20px;
    position: relative;
    &__menu {
        position: absolute;
        top: 15px;
        right: 15px;
    }
    &__title{
        color: #3A3A3A;
        margin-bottom: 15px;
    }
    &__left{
        width: 30%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    &__left-title {
        color: #3A3A3A;
    }
    &__right {
        width: 70%;
        display: flex;
        flex-direction: column;

        &.fulfillment-type {
            width: 20%;
            margin-left: 30px
        }
    }
    &__info{
        flex: 1;
        display: flex;
        align-items: center;

    }
    &__img{
        padding: 10px 30px;
        img,video{
            max-width: 60px;
            height: auto;
        }
    }
    &__name{
        margin-bottom: 15px;
        margin-left: 15px;
        color: #00A4EB;
        text-decoration: underline;
    }
    &__progress{
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .has-tooltip {
            display: flex;

            .ad-deployment-tooltip {
                margin: -3px 0 0 5px;
            }
        }
    }
    &__complete {
        display: flex;
        align-items: center;
        margin: 10px 0 30px;
    }
    &__complete-label {
        display: flex;
        & > div:first-child {
            margin-right: 10px;
            svg {
                margin-right: 8px;
            }
        }
    }
    &__complete-actions {
        margin-left: auto;
        button {
            margin-left: 15px;
        }
    }
}

</style>
