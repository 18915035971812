<template>
    <div class="delete-button">
        <styled-dialog v-model="dialogOpen">
            <slot name="confirmation" />
            <div class="text-xs-center">
                <styled-button
                    class="mt-4"
                    :loading="loading"
                    @click="deleteDeployment">
                    YES, CONTINUE &amp; DELETE
                </styled-button>
            </div>
            <template #actions>
                <a
                    href="#"
                    class="red--text"
                    @click.prevent="dialogOpen = false">
                    Cancel
                </a>
            </template>
        </styled-dialog>

        <styled-button
            text
            :loading="loading"
            @click="handleDelete">
            Delete Deployment
        </styled-button>
    </div>
</template>

<script>
import StyledDialog from '@/components/globals/StyledDialog';
import StyledButton from '@/components/globals/StyledButton';
import { mapActions } from 'vuex';

export default {
    components: {
        StyledButton,
        StyledDialog
    },
    props: {
        deployment: {
            type: Object,
            required: true
        },
        confirm: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            loading: false,
            dialogOpen: false,
        };
    },
    methods: {
        ...mapActions('playbookManagement', [
            'loadDeployments'
        ]),
        handleDelete() {
            if (this.confirm) {
                this.dialogOpen = true;
            } else {
                this.deleteDeployment();
            }
        },
        async deleteDeployment() {
            try {
                this.loading = true;
                await this.$apiRepository.deleteAdDeployment(this.deployment.id);

                if (this.confirm) {
                    this.dialogOpen = false;
                }

                // Reload deployments to remove the item
                await this.loadDeployments();

            } catch(error) {
                console.error('Error deleting ad deployment', error.response ?? error);
                this.$flash('Error deleting ad deployment.  Please try again or contact support', 'red');
            } finally {
                this.loading = false;
            }
        },
    }
};
</script>