<template>
    <component
        :is="element"
        ref="wrapper"
        class="grey-bg"
        :class="{
            'draft': deployment.status === STATUS_DRAFT,
            'active': flashActive
        }">
        <slot />
    </component>
</template>

<script>
import { sleep } from '@/helpers';
import {
    STATUS_DRAFT
} from '../constants';

export default {
    props: {
        element: {
            type: String,
            default: 'div'
        },
        deployment: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            flashActive: false,
            STATUS_DRAFT
        };
    },
    computed: {
        isActive() {
            return this.deployment.id == this.$route.query.ad_deployment_id;
        },
    },
    async mounted() {
        if (this.isActive) {
            await sleep(500);
            this.$scrollTo(this.$refs.wrapper, 500, {
                easing: 'ease-in',
                offset: -200,
                onDone: async() => {
                    this.flashActive = true;
                }
            });
        }
    }
};
</script>


<style lang="scss" scoped>
.grey-bg {
    background: #EDF2F5;
    border-bottom: 1px solid #CED4D8;
}
.draft {
    background: #D0EEFC;
}
.active {
    animation: highlight-flash 0.75s ease-in-out forwards;
    &.draft {
        animation: highlight-flash-draft 0.75s ease-in-out forwards;
    }
}

@keyframes highlight-flash-draft {
  from {
    background-color: $green;
  }
  to {
    background-color: #D0EEFC;
  }
}

@keyframes highlight-flash {
  from {
    background-color: $green;
  }
  to {
    background-color: #EDF2F5;
  }
}
</style>