var render = function render(){var _vm=this,_c=_vm._self._c;return _c('deployment-wrapper',{attrs:{"element":"tr","deployment":_vm.deployment}},[_c('td',{staticClass:"centered"},[(_vm.isStaticDeployment)?_c('thumbnail',{attrs:{"ads":_vm.ads}}):_c('icon',{attrs:{"name":"car"}})],1),_c('td',[_c('span',{staticClass:"item-name"},[_c('row-label',{attrs:{"deployment":_vm.deployment}})],1)]),_c('td',[_c('span',{staticClass:"circle",class:{
                'active': _vm.deployment.status === _vm.STATUS_COMPLETE,
                'draft': _vm.deployment.status === _vm.STATUS_DRAFT
            }}),(_vm.deployment.status === _vm.STATUS_COMPLETE)?_c('span',{staticClass:"ml-2"},[_vm._v("Deployed")]):_vm._e(),(_vm.deployment.status === _vm.STATUS_DRAFT)?_c('span',{staticClass:"ml-2"},[_vm._v("Draft")]):_vm._e()]),_c('td',[_c('div',[_vm._v(_vm._s(_vm.createdAt.date))]),_c('div',[_vm._v(_vm._s(_vm.createdAt.time))])]),_c('td',[_c('div',{staticClass:"fulfillment-type"},[_c('span',{staticClass:"item-name"},[_vm._v(_vm._s(_vm.fulfillmentType))]),_c('styled-tooltip',{staticClass:"fulfillment-type-tooltip",attrs:{"position":"top"},scopedSlots:_vm._u([(_vm.fulfillmentType === 'Mixed')?{key:"content",fn:function(){return _vm._l((_vm.deployment.dealer_plays.data),function({ id, fulfillment_type, platform_id}){return _c('p',{key:id},[_vm._v(" "+_vm._s(_vm.getTooltipContent(platform_id, fulfillment_type))+" ")])})},proxy:true}:{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('campaignManagement.fulfillmentType.tooltips.' + _vm.fulfillmentType.toLowerCase()))+" ")]},proxy:true}],null,true)},[_c('icon',{attrs:{"name":"question-circle","size":"10","color":"gray"}})],1)],1)]),_c('td',[_vm._v(" "+_vm._s(_vm.userName)+" ")]),_c('td',[(_vm.deployment.status === _vm.STATUS_DRAFT)?_c('router-link',{staticClass:"item-complete",attrs:{"to":{
                name: 'ad-deployment',
                params: {
                    ad_deployment_id: _vm.deployment.id,
                    dealer_id: _vm.dealer.id,
                }
            }}},[_vm._v(" Continue ")]):_c('div',[_c('div',[_vm._v(_vm._s(_vm.completedAt.date))]),_c('div',[_vm._v(_vm._s(_vm.completedAt.time))])])],1),_c('td',{staticClass:"centered"},_vm._l((_vm.channels),function(channel){return _c('span',{key:channel.name,staticClass:"mr-2"},[_c('icon',{attrs:{"size":"20","name":channel.icon_name}})],1)}),0),_c('td',[_c('vertical-menu',[(_vm.deployment.status === _vm.STATUS_COMPLETE)?_c('view-ads-button',{attrs:{"object-type":"campaigns","deployment":_vm.deployment}}):_vm._e(),(_vm.deployment.status === _vm.STATUS_COMPLETE)?_c('view-ads-button',{attrs:{"object-type":"adsets","deployment":_vm.deployment}}):_vm._e(),(_vm.deployment.status === _vm.STATUS_COMPLETE)?_c('view-ads-button',{attrs:{"deployment":_vm.deployment}}):_vm._e(),(_vm.deployment.status === _vm.STATUS_COMPLETE)?_c('view-previews-button',{attrs:{"deployment":_vm.deployment}}):_vm._e(),(_vm.deployment.status === _vm.STATUS_DRAFT)?_c('delete-button',{attrs:{"deployment":_vm.deployment}}):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }