/**
 * Prepares an array of strings for search
 * by converting them into a single lowercase
 * string
 *
 * @param {*} arr
 * @returns
 */
const prepareSearchTerms = (arr) => {
    if (!arr) {
        return '';
    }

    return arr.map(string => string?.toLowerCase() ?? '').join(' ');
};


/**
 * Retrieves a string of lowercase terms for the
 * deployment to search against
 *
 * @param {*} deployment
 * @returns
 */
const getDeploymentSearchTerms = (deployment) => {

    const {
        ads = [],
        platforms = [],
    } = deployment.configuration ?? {};

    const adNames = ads.map(ad => ad?.display_name ?? '');

    return prepareSearchTerms([
        prepareSearchTerms(platforms),
        prepareSearchTerms(adNames),
        deployment.status
    ]);
};

export default getDeploymentSearchTerms;