var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab-content"},[(_vm.loading)?_c('div',{staticClass:"loading-wrapper"},[_c('styled-loader',{attrs:{"size":"25","label":"Loading ad deployments..."}})],1):(!_vm.deployments.length)?_c('no-deployments'):_c('div',{staticClass:"table-wrapper",attrs:{"id":"deployed-ads"}},[(_vm.activeDeployments.length)?_c('div',{staticClass:"currently"},[_c('div',{staticClass:"currently-title"},[_vm._v(" CURRENTLY DEPLOYING... ")]),_c('div',{staticClass:"currently-blocks"},_vm._l((_vm.activeDeployments),function(deployment){return _c('active-deployment',{key:deployment.id,attrs:{"deployment":deployment}})}),1)]):_vm._e(),_c('div',{staticClass:"ads-table-header"},[_c('div',{staticClass:"header-elems"},[_c('div',{staticClass:"header-search"},[_c('v-text-field',{staticClass:"styled-field",attrs:{"label":"SEARCH","placeholder":"Enter terms..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"header-checks"},[_c('styled-checkboxes',{attrs:{"items":[{
                            text: 'Deployed',
                            value: _vm.STATUS_COMPLETE
                        },{
                            text: 'Drafts',
                            value: _vm.STATUS_DRAFT
                        }],"inline":""},model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}})],1)])]),_c('v-data-table',{staticClass:"pb-4",attrs:{"id":"ads-table","items":_vm.filteredDeployments,"search":_vm.search,"headers":_vm.HEADERS,"expand":true,"hide-actions":"","disable-initial-sort":""},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('row',{key:props.item.id,attrs:{"deployment":props.item}})]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"py-5 text-xs-center"},[_vm._v(" No Available Data... ")])]},proxy:true}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }