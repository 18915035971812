<template>
    <div class="tab-content">
        <div
            v-if="loading"
            class="loading-wrapper">
            <styled-loader
                size="25"
                label="Loading ad deployments..." />
        </div>
        <no-deployments v-else-if="!deployments.length" />
        <div
            v-else
            id="deployed-ads"
            class="table-wrapper">
            <div
                v-if="activeDeployments.length"
                class="currently">
                <div class="currently-title">
                    CURRENTLY DEPLOYING...
                </div>
                <div class="currently-blocks">
                    <active-deployment
                        v-for="deployment in activeDeployments"
                        :key="deployment.id"
                        :deployment="deployment" />
                </div>
            </div>

            <!-- table + header-->
            <div class="ads-table-header">
                <div class="header-elems">
                    <div class="header-search">
                        <v-text-field
                            v-model="search"
                            label="SEARCH"
                            placeholder="Enter terms..."
                            class="styled-field" />
                    </div>
                    <div class="header-checks">
                        <styled-checkboxes
                            v-model="filters"
                            :items="[{
                                text: 'Deployed',
                                value: STATUS_COMPLETE
                            },{
                                text: 'Drafts',
                                value: STATUS_DRAFT
                            }]"
                            inline />
                    </div>
                </div>
            </div>
            <v-data-table
                id="ads-table"
                :items="filteredDeployments"
                :search="search"
                :headers="HEADERS"
                :expand="true"
                class="pb-4"
                hide-actions
                disable-initial-sort>
                <template #items="props">
                    <row
                        :key="props.item.id"
                        :deployment="props.item" />
                </template>
                <template #no-data>
                    <div class="py-5 text-xs-center">
                        No Available Data...
                    </div>
                </template>
            </v-data-table>
            <!-- end of table-->
        </div>
    </div>
</template>

<script>
import StyledLoader from '@/components/globals/StyledLoader';
import StyledCheckboxes from '@/components/globals/StyledCheckboxes';
import { mapActions, mapState } from 'vuex';
import Row from './components/Row';
import ActiveDeployment from './components/ActiveDeployment';
import NoDeployments from './components/NoDeployments';
import getDeploymentSearchTerms from './helpers/getDeploymentSearchTerms';
import {
    HEADERS,
    STATUS_DRAFT,
    STATUS_COMPLETE,
    STATUS_QUEUED,
    STATUS_FAILED,
    STATUS_BUILDING
} from './constants';
import orderBy from 'lodash/orderBy';
import moment from 'moment';

export default {
    name: 'DeployedAds',
    components: {
        Row,
        ActiveDeployment,
        StyledCheckboxes,
        StyledLoader,
        NoDeployments
    },
    data() {
        return {
            filters: [STATUS_COMPLETE,STATUS_DRAFT],
            error: null,
            loading: false,
            search: '',
            HEADERS,
            STATUS_COMPLETE,
            STATUS_DRAFT
        };
    },
    computed: {
        ...mapState({
            dealerId: (state) => state.dealer.currentDealerId,
            deployments: (state) => state.playbookManagement.deployments
        }),
        formattedDeployments() {
            const items = this.deployments
                .map(deployment => {

                    // Format terms for search matching
                    deployment.terms = getDeploymentSearchTerms(deployment);

                    return deployment;
                });

            return orderBy(items, [
                (item) => item.status === STATUS_DRAFT,
                (item) => moment(item.created_at.date).unix()
            ],['desc','desc']);
        },
        activeDeployments() {
            return this.formattedDeployments
                .filter(deployment => [STATUS_QUEUED, STATUS_FAILED, STATUS_BUILDING].includes(deployment.status));
        },
        filteredDeployments() {
            return this.formattedDeployments
                .filter(deployment => {

                    // Apply search terms if they're set
                    // note that the terms field is being
                    // computed inside the logic for
                    // `formattedDeployments`
                    let searchMatch = true;
                    if (this.search?.length) {
                        searchMatch = deployment.terms.includes(
                            this.search.toLowerCase()
                        );
                    }

                    return (
                        [STATUS_DRAFT, STATUS_COMPLETE].includes(deployment.status)
                        && this.filters.includes(deployment.status)
                        && searchMatch
                    );
                });
        }
    },
    mounted() {
        this.initAdDeployments();
    },
    methods: {
        ...mapActions('playbookManagement', [
            'loadDeployments'
        ]),
        async initAdDeployments() {
            try {
                this.error = null;
                this.loading = true;

                await this.loadDeployments();
            } catch(error) {
                console.log('Error fetching deployments:', error.response ?? error);
                this.error = 'There was an error fetching deployments';
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.loading-wrapper {
    text-align: center;
    padding: 200px 0;
}
.ads-table-header{
    border-bottom: 1px solid #DADADA;
    width: calc(100% + 40px);
    transform: translateX(-20px);
    padding: 25px;
}
.header-search{
    max-width: 300px;
}
.header-elems {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>

<style lang="scss">
#ads-table{
    table.v-table{
        border-collapse: separate;
        border-spacing: 0 3px;
    }
    table.v-table tbody td {
        padding: 15px 0;
        border-bottom: 1px solid #C9CFD3;
    }
    .img-holder{
        padding-left: 30px;
        img, video{
            max-width: 60px;
            height: auto;
        }
    }
    .item-name{
        text-decoration: underline;
        color: #00A2EA;
    }
    .item-complete{
        text-decoration: underline;
        color: #00A2EA;
        cursor: pointer;
    }
    .dots{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 5px;
        cursor: pointer;
        padding-right: 10px;
        div{
            width:4px;
            height: 4px;
            margin-bottom: 4px;
            border-radius: 50%;
            background: #909FA8;
        }
    }
}
.currently{
    padding: 30px 20px 0;
    border-bottom: 1px solid #DADADA;
    width: calc(100% + 40px);
    transform: translateX(-20px);
}
.currently-title{
    color: #3A3A3A;
    margin-bottom: 15px;
}
</style>
