<template>
    <div class="no-deps">
        <div class="no-deps__bigtitle">
            You have no ads deployed yet. Let's change that!
        </div>
        <div class="no-deps__desc">
            You currently do not have any Ads deployed through our new Ad Deployment process. Use the links below to get started!
        </div>
        <div class="no-deps__block">
            <div class="no-deps__left">
                <div class="no-deps__title">
                    Create a new ad with our Ad Builder
                </div>
                <div class="no-deps__holder">
                    <div class="no-deps__image no-deps__image-wider">
                        <img
                            class="placeholder"
                            src="/img/studio.jpeg">
                    </div>
                    <div class="no-deps__info">
                        <div class="no-deps__desc">
                            Open our Ad Builder to create a new Ad that can be used across all your active channels
                        </div>
                        <div
                            class="create__button"
                            @click.stop="showDropdown">
                            <div class="create__circle">
                                <div class="create__plus">
                                    +
                                </div>
                            </div>
                            CREATE NEW
                            <create-new-dropdown
                                v-if="dropdownActive"
                                v-outside-click="hideDropdown" />
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="no-deps__right left-border">
                <div class="no-deps__title">
                    Or browse our Ad Library
                </div>
                <div class="no-deps__holder">
                    <div class="no-deps__image">
                        <img
                            class="placeholder"
                            src="/img/library.jpeg">
                    </div>
                    <div class="no-deps__info no-deps__info-wider">
                        <div class="no-deps__desc">
                            Jump start your ad creation by customizing
                            an ad from our ad library.
                        </div>
                        <router-link
                            class="no-deps__link"
                            :to="{name: 'ads-library'}">
                            BROWSE ADS LIBRARY
                        </router-link>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</template>
<script>
import CreateNewDropdown from '@/components/globals/CreateNewDropdown';

export default {
    components: {
        CreateNewDropdown
    },
    data() {
        return {
            dropdownActive: false,
        };
    },
    methods: {
        showDropdown() {
            this.dropdownActive = true;
        },
        hideDropdown() {
            this.dropdownActive = false;
        },
    }
};
</script>

<style lang="scss" scoped>
.no-deps{
    padding: 60px 100px;
    &__bigtitle{
        font-size: 30px;
        color: #00A2EA;
    }
    &__block{
        display: flex;
        justify-content: space-between;
        margin-top: 45px;
        margin-bottom: 45px;
    }
    &__title{
        font-size: 22px;
        color: #00A2EA;
        margin-bottom: 15px;
    }
    &__right{
        width: 45%;
        margin-left: 5%;
        padding-left: 5%;
        position: relative;
    }
    &__left{
        width: 50%;
        position: relative;
    }
    &__holder{
        display: flex;
        align-items: center;
        height: 100%;
        padding-bottom: 45px;
    }
    &__image{
        width: 40%;
        margin-right: 10%;
        &-wider{
            width: 50%;
        }
        img{
            max-width: 100%;
            height: auto;
        }
    }
    &__info{
        width: 40%;
        &-wider{
            width: 50%;
        }
    }
    &__link{
        color: white;
        background: #00A4EB;
        font-weight: 700;
        padding: 15px 20px;
        display: inline-block;
        white-space: nowrap;
        text-decoration: none;
        border-radius: 5px;
        margin-top: 30px;
    }
}
.create{
    &__button{
        display: inline-block;
        margin-top: 30px;
        position: relative;
        color: white;
        padding: 14px 40px;
        padding-left: 60px;
        border-radius: 50px;
        background: #7FD41B;
        font-weight: 600;
        cursor: pointer;
        &:after{
            content: '';
            position: absolute;
            width: 11px;
            height:11px;
            background: white;
            right: 18px;
            top: 16px;
            transform: rotate(45deg);
            z-index: 1;
        }
        &:before{
            content: '';
            position: absolute;
            width: 20px;
            height:15px;
            background: #7FD41B;
            right: 13px;
            top: 7px;
            z-index: 2;
        }
    }
    &__circle{
        position: absolute;
        left:0;
        width:50px;
        height: 50px;
        background: white;
        border-radius: 50%;
        display: flex;
        color: #7FD41B;
        align-items: center;
        justify-content: center;
        padding-bottom: 2px;
        font-size: 50px;
        top:-1px;
        box-shadow: 0 0 5px 2px rgba(0,0,0,0.25);
    }
    &__plus{
        background-color: #7FD41B;
        color: transparent;
        text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.2);
        -webkit-background-clip: text;
        -moz-background-clip: text;
        background-clip: text;
    }
}
.left-border{
    &:after{
        content: '';
        position: absolute;
        width: 1px;
        height: 80%;
        top: 20%;
        left: 0;
        background:#989898;
    }
}
</style>