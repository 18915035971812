<template>
    <styled-button
        class="previews-button"
        :text="!button"
        :small="button"
        :loading="loading"
        @click="handleViewPreviews">
        View Previews
    </styled-button>
</template>

<script>
import StyledButton from '@/components/globals/StyledButton';
import { mapActions } from 'vuex';
import { OBJECT_TYPE_AD } from '@/helpers/globals';

export default {
    components: {
        StyledButton
    },
    props: {
        deployment: {
            type: Object,
            required: true
        },
        play: {
            type: Object,
            default: undefined
        },
        button: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            loading: false,
        };
    },
    computed: {
        /**
         * Navigates the plays to act on based on whether
         * this is being called for a single play or the
         * whole deployment
         */
        targetPlays() {
            if (this.play) {
                return [this.play];
            }

            return this.deployment.dealer_plays?.data ?? [];
        },
        /**
         * Builds up the preview data data to be passed into
         * `playbookManagement/getPreviewUrl` in this format:
         * ```js
         * {
         *   [platform]: {
         *     ads: [{
         *       external_id: "1234",
         *       campaign_id: "1234",
         *       ad_set_id: "1234"
         *     }]
         *   }
         * }
         */
        previewData() {
            return this.targetPlays.reduce((data, play) => {
                const ads = play.object_ids.map(({ ad_id, campaign_id, ad_set_id }) => ({
                    external_id: ad_id,
                    campaign_id,
                    ad_set_id
                }));
                data[play.platform.name] = {
                    [OBJECT_TYPE_AD]: ads
                };
                return data;
            }, {});
        }
    },
    methods: {
        ...mapActions('playbookManagement', [
            'getPreviewUrl'
        ]),
        async handleViewPreviews() {
            try {
                this.loading = true;
                const url = await this.getPreviewUrl(this.previewData);
                window.open(url);
            } catch(error) {
                console.error('Error generating preview URL:', error.response ?? error);
                this.$flash('Error generating preview URL.  Please try again or contact support', 'red');
            } finally {
                this.loading = false;
            }

        }
    }
};
</script>

<style lang="scss" scoped>
button.previews-button {
    text-decoration: none;
    display: block;
    text-align: left;
    &:hover {
        text-decoration: underline;
    }
}
</style>