<template>
    <div class="ad-asset">
        <img
            v-if="type === 'image'"
            :style="assetStyle"
            :src="url">
        <video
            v-if="type === 'video'"
            :style="assetStyle"
            muted
            paused>
            <source
                :src="url"
                :type="mime">
        </video>
    </div>
</template>

<script>
export default {
    props: {
        ad: {
            type: Object,
            required: true
        },
        size: {
            type: String,
            default: undefined
        }
    },
    computed: {
        assetStyle() {
            if (this.size) {
                return {
                    display: 'block',
                    width: this.size,
                    height: this.size,
                    objectFit: 'cover',
                };
            }

            return {};
        },
        asset() {
            return this.ad.configuration?.all_platforms?.creatives?.[0]?.assets?.[0] ?? {};
        },
        type() {
            return this.asset.asset_type?.name ?? '';
        },
        url() {
            if (this.type === 'video') {
                return this.asset?.url ?? '';
            } else {
                return this.ad.thumbnail ?? this.asset?.url ?? '';
            }
        },
        mime() {
            return this.asset?.mime_type ?? '';
        }
    }
};
</script>