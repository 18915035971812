<template>
    <deployment-wrapper
        element="tr"
        :deployment="deployment">
        <td class="centered">
            <thumbnail
                v-if="isStaticDeployment"
                :ads="ads" />
            <icon
                v-else
                name="car" />
        </td>
        <td>
            <span class="item-name">
                <row-label :deployment="deployment" />
            </span>
        </td>
        <td>
            <span
                class="circle"
                :class="{
                    'active': deployment.status === STATUS_COMPLETE,
                    'draft': deployment.status === STATUS_DRAFT
                }" />
            <span
                v-if="deployment.status === STATUS_COMPLETE"
                class="ml-2">Deployed</span>
            <span
                v-if="deployment.status === STATUS_DRAFT"
                class="ml-2">Draft</span>
        </td>
        <td>
            <div>{{ createdAt.date }}</div>
            <div>{{ createdAt.time }}</div>
        </td>
        <td>
            <div class="fulfillment-type">
                <span class="item-name">{{ fulfillmentType }}</span>

                <styled-tooltip
                    class="fulfillment-type-tooltip"
                    position="top">
                    <template
                        v-if="fulfillmentType === 'Mixed'"
                        #content>
                        <p
                            v-for="{ id, fulfillment_type, platform_id} in deployment.dealer_plays.data"
                            :key="id">
                            {{ getTooltipContent(platform_id, fulfillment_type) }}
                        </p>
                    </template>
                    <template
                        v-else
                        #content>
                        {{ $t('campaignManagement.fulfillmentType.tooltips.' + fulfillmentType.toLowerCase()) }}
                    </template>
                    <icon
                        name="question-circle"
                        size="10"
                        color="gray" />
                </styled-tooltip>
            </div>
        </td>
        <td>
            {{ userName }}
        </td>
        <td>
            <router-link
                v-if="deployment.status === STATUS_DRAFT"
                :to="{
                    name: 'ad-deployment',
                    params: {
                        ad_deployment_id: deployment.id,
                        dealer_id: dealer.id,
                    }
                }"
                class="item-complete">
                Continue
            </router-link>
            <div v-else>
                <div>{{ completedAt.date }}</div>
                <div>{{ completedAt.time }}</div>
            </div>
        </td>
        <td class="centered">
            <span
                v-for="(channel) in channels"
                :key="channel.name"
                class="mr-2">
                <icon
                    size="20"
                    :name="channel.icon_name" />
            </span>
        </td>
        <td>
            <vertical-menu>
                <view-ads-button
                    v-if="deployment.status === STATUS_COMPLETE"
                    object-type="campaigns"
                    :deployment="deployment" />
                <view-ads-button
                    v-if="deployment.status === STATUS_COMPLETE"
                    object-type="adsets"
                    :deployment="deployment" />
                <view-ads-button
                    v-if="deployment.status === STATUS_COMPLETE"
                    :deployment="deployment" />
                <view-previews-button
                    v-if="deployment.status === STATUS_COMPLETE"
                    :deployment="deployment" />
                <delete-button
                    v-if="deployment.status === STATUS_DRAFT"
                    :deployment="deployment" />
            </vertical-menu>
        </td>
    </deployment-wrapper>
</template>

<script>
import VerticalMenu from '@/components/globals/VerticalMenu';
import Icon from '@/components/globals/Icon';
import moment from 'moment';
import { getChannelByPlatformName } from '@/components/onboarding/channel-setup/channels';
import RowLabel from './RowLabel';
import ViewAdsButton from './ViewAdsButton';
import ViewPreviewsButton from './ViewPreviewsButton';
import DeleteButton from './DeleteButton';
import DeploymentWrapper from './DeploymentWrapper';
import Thumbnail from './Thumbnail';
import {
    STATUS_DRAFT,
    STATUS_COMPLETE
} from '../constants';
import { mapState } from 'vuex';
import StyledTooltip from '@/components/globals/StyledTooltip.vue';
import {
    FACEBOOK_BB_PLATFORM_ID,
    TIKTOK_BB_PLATFORM_ID,
    SNAPCHAT_BB_PLATFORM_ID,
    PINTEREST_BB_PLATFORM_ID,
} from '@/helpers/globals';
import { STATIC_DEPLOYMENT_TYPE } from '@/components/ad-deployment/store/constants';

export default {
    name: 'Row',
    components: {
        StyledTooltip,
        RowLabel,
        Icon,
        Thumbnail,
        VerticalMenu,
        ViewAdsButton,
        ViewPreviewsButton,
        DeleteButton,
        DeploymentWrapper
    },
    props: {
        deployment: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            STATUS_DRAFT,
            STATUS_COMPLETE,
            platformName: {
                [FACEBOOK_BB_PLATFORM_ID]: 'Facebook',
                [TIKTOK_BB_PLATFORM_ID]: 'TikTok',
                [SNAPCHAT_BB_PLATFORM_ID]: 'Snapchat',
                [PINTEREST_BB_PLATFORM_ID]: 'Pinterest',
            }
        };
    },
    computed: {
        ...mapState({
            dealer: (state) => state.dealer.currentDealer,
        }),
        ads() {
            return this.deployment?.ads;
        },
        userName() {
            return this.deployment.user?.name ?? 'Anonymous';
        },
        channels() {
            return this.deployment?.platforms?.map(getChannelByPlatformName);
        },
        createdAt() {
            const date = moment.utc(this.deployment.created_at.date).local();
            return {
                date: date.format('M/D/YY'),
                time: date.format('h:mm a')
            };
        },
        completedAt() {
            if (!this.deployment.completed_at) {
                return null;
            }

            const date = moment.utc(this.deployment.completed_at.date).local();
            return {
                date: date.format('M/D/YY'),
                time: date.format('h:mm a')
            };
        },
        fulfillmentType() {
            const dealerPlays = this.deployment.dealer_plays.data;
            const onlyHasAutomated = dealerPlays.every((play) => play?.fulfillment_type === 'automated');
            const onlyHasAssisted = dealerPlays.every((play) => play?.fulfillment_type === 'assisted');

            if (onlyHasAutomated) {
                return this.$t('campaignManagement.fulfillmentType.automated');
            } else if (onlyHasAssisted) {
                return this.$t('campaignManagement.fulfillmentType.assisted');
            } else {
                return this.$t('campaignManagement.fulfillmentType.mixed');
            }
        },
        isStaticDeployment() {
            if (!this.deployment.deployment_type?.length) {
                return true;
            }

            return this.deployment.deployment_type === STATIC_DEPLOYMENT_TYPE;
        }
    },
    methods: {
        getTooltipContent(platformId, fulfillmentType) {
            const fulfillmentTypeText = this.$t(`campaignManagement.fulfillmentType.${fulfillmentType}`);
            return `${this.platformName[platformId]} - ${fulfillmentTypeText}`;
        }
    }
};
</script>

<style lang="scss" scoped>
.fulfillment-type {
    display: flex;
    align-items: center;

    .fulfillment-type-tooltip {
        margin: -7px 0 0 7px;
    }
}
</style>
