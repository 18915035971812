export const STATUS_BUILDING = 'building';
export const STATUS_COMPLETE = 'completed';
export const STATUS_FAILED = 'failed';
export const STATUS_QUEUED = 'queued';
export const STATUS_DRAFT = 'draft';

export const HEADERS = [
    {
        text: '',
        align: 'left',
        sortable: false,
        width: '10%',
    },
    {
        text: 'NAME',
        align: 'left',
        sortable: true,
        value: 'label',
        width: '17.5%',
    },
    {
        text: 'STATUS',
        align: 'left',
        value: 'status',
        sortable: true,
        width: '12.5%',
    },
    {
        text: 'CREATED AT',
        align: 'left',
        sortable: true,
        value: 'created_at.date',
        width: '12.5%',
    },
    {
        text: 'FULFILLMENT TYPE',
        align: 'left',
        sortable: false,
        value: 'fulfillmentType',
        width: '12.5%',
    },
    {
        text: 'CREATED BY',
        align: 'left',
        sortable: true,
        value: 'user.name',
        width: '12.5%',
    },
    {
        text: 'DEPLOYED',
        align: 'left',
        sortable: true,
        value: 'completed_at.date',
        width: '7.5%',
    },
    {
        text: 'CHANNELS',
        align: 'center',
        sortable: false,
        width: '12.5%',
    },
    {
        text: '',
        align: 'center',
        sortable: false,
        width: '2%',
    },
];
