<template>
    <styled-tooltip
        class="ad-deployment-tooltip"
        position="top">
        <template #content>
            {{ tooltipContent }}
        </template>
        <icon
            name="question-circle"
            size="10"
            color="gray" />
    </styled-tooltip>
</template>

<script>
import Icon from '@/components/globals/Icon.vue';
import StyledTooltip from '@/components/globals/StyledTooltip.vue';

export default {
    name: 'AdDeploymentTooltip',
    components: {
        Icon,
        StyledTooltip
    },
    props: {
        contentPath: {
            type: String,
            required: true,
        },
        contentVariables: {
            type: Object,
            required: false,
            default: () => ({}),
        }
    },
    computed: {
        tooltipContent() {
            if (Object.keys(this.contentVariables).length) {
                return this.$t(this.contentPath, this.contentVariables);
            }

            return this.$t(this.contentPath);
        }
    }
};
</script>

<style lang="scss" scoped>
.ad-deployment-tooltip {
    margin-top: -10px;
}
</style>
